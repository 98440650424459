import { Box, Typography } from '@mui/material';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const FeedbackCarousel = () => {

    const feedbackData = [
        { message: "Great service! Highly recommended Gym.", clientName: "Mr. Mahesh Kale" },
        { message: "Amazing experience with the trainer, he teach to grow and also has great knowledge of proteing , vitamin guide.", clientName: "Mr. Pramod Gadekar" },
        { message: "Friendly community atmosphere! I've made new workout buddies and the social events organized by the gym are a great way to connect with fellow members. ", clientName: "Mr. Uddhav" },
        { message: "Incredible personal trainers! They are knowledgeable, motivating, and create personalized workout plans based on your goals. The gym is well-maintained, and there's a positive energy that keeps me coming back. Definitely worth the investment in my health..", clientName: "Mr. Sanjay deshpande" },
        { message: "Love the cleanliness and organization of this gym! The staff is always wiping down equipment, and the layout is intuitive. They also offer a variety of classes that cater to different fitness levels. It's my go-to spot for staying in shape..", clientName: "Mrs. suhani" },
        { message: "Fantastic gym with top-notch facilities! The variety of workout equipment is impressive, and the cleanliness of the gym is unmatched. The staff is friendly and always willing to help. I've seen great results since joining, and I highly recommend it to anyone looking to get fit.", clientName: "Mr. Janardhan naval" },
    ];
    return <Box maxWidth='74rem' margin={"auto"} marginTop="9rem" p={2}>

        <Typography textAlign={"left"} className="fitfor-heading" data-aos="fade-up" data-aos-duration="800">Our Testimonials</Typography>
        <Box textAlign={"center"} sx={{ margin: 'auto', paddingTop: '3rem' }} data-aos="fade-up" data-aos-duration="800">
            <Carousel
                showThumbs={false}
                autoPlay={true}
                interval={3000}
                infiniteLoop
            >
                {feedbackData.map((feedback, index) => (
                    <div key={index} className="feedback-item">
                        <p className="feedback-message">{feedback.message}</p>
                        <p className="client-name">- {feedback.clientName}</p>
                    </div>
                ))}
            </Carousel>
        </Box>
    </Box>
};

export default FeedbackCarousel;
