import { Box, Typography } from '@mui/material';
import React from 'react'

const FitForLifestyle = () => {
    return <>
        <img src="/images/gradient.png" alt="gradient" className='fitfor-lifestyle-gradient' />

        <Box p={2}>

            <Typography className="fitfor-heading" data-aos="fade-up" data-aos-duration="800">Fit for your lifestyle</Typography>

            <Typography className='fitfor-desc' data-aos="fade-up" data-aos-duration="800">
                Wake up with a sunrise meditation, sweat it out with lunchtime HIIT, or unwind with an evening flow. You’ll find movement for every mood with classes sorted by time, style, and skill level.
            </Typography>

            <Box textAlign={"center"} data-aos="fade-up" data-aos-duration="800">
                <img src="/images/fitfor-image.png" alt="fit-image" className='fitfor-image' />
            </Box>

        </Box>
    </>
}

export default FitForLifestyle;