import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import React, { useState } from 'react';
import "../styles/navbar.css"
import { useNavigate } from 'react-router-dom';
import ContactUsForm from './ContactUsForm';

const pages = [
    {
        pageName: 'Home',
        path: '/'
    },
    {
        pageName: 'Gallery',
        path: '/gallery'
    },
    {
        pageName: 'About Us',
        path: '/about-us'
    }
];

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Navbar({ isMobile }) {

    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const onClose = () => setIsOpen(false)

    const handleNavigate = path => navigate(path)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static" sx={{ boxShadow: 'none', color: 'white', background: 'transparent', }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                    <Box
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        className='ab-brand-name'
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src="/logo.jpeg" alt="logo" height={67} width={67} />
                    </Box>

                    <Box sx={{ flexGrow: 0.72, display: { xs: 'flex', md: 'flex' } }}>
                        <IconButton
                            size="small"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon sx={{ display: { xs: 'block', md: 'none' } }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' }
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.pageName}>
                                    <Typography onClick={() => handleNavigate(page.path)} textAlign="center">{page.pageName}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        className='ab-brand-name'
                        href="/"
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src="/logo.jpeg" alt="logo" height={67} width={67} />
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Box
                                key={page.pageName}
                                onClick={() => handleNavigate(page.path)}
                                sx={{ my: 2, color: 'white', display: 'block', mx: 2, cursor: 'pointer' }}
                            >
                                {page.pageName}
                            </Box>
                        ))}
                    </Box>

                    <Box >
                        {
                            isMobile &&
                            <Button style={{
                                backgroundImage: "url('/images/reactangle-button.png')",
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                paddingRight: '4rem',
                                paddingLeft: '1.2rem',
                                color: 'white',
                                textTransform: "none",
                            }}
                                onClick={() => setIsOpen(true)}
                            >
                                Contact Us
                            </Button>
                        }
                    </Box>
                </Toolbar>
            </Container>

            <ContactUsForm isOpen={isOpen} onClose={onClose} />
        </AppBar>
    );
}
export default Navbar;