import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import Navbar from '../components/Navbar';
import "../styles/home.css"
import FitForLifestyle from '../components/Home/FitForLifestyle';
import Packages from '../components/Home/Packages';
import FeedbackCarousel from '../components/FeedbackCarousel';
import Faqs from '../components/Home/Faqs';
import Footer from '../components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = ({ isMobile }) => {

    useEffect(() => {
        AOS.init();
    }, []);

    return <Box style={{
        backgroundImage: "url('/images/landing.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100vw',
        height: "100vh"
    }}
    >
        <Box
            sx={!isMobile ? { padding: '0 4rem 0 4rem' } : {}}
        >
            <Navbar isMobile={!isMobile} />

            <Box className="landing-desciption" style={isMobile ? { marginLeft: 0, marginTop: '2rem' } : {}}>
                <Box className="unleash-txt">
                    <span className='Unleash-word'> AB FITNESS </span>ZONE
                    <br />
                    Unisex Gym
                </Box>
                <Typography className='landing-desc-txt'>
                    Get ready to sweat it out and achieve your fitness goals with our high-energy fitness classes! Our classes are designed to cater to all fitness levels and provide a fun and motivating environment to help you reach your peak performance.
                </Typography>

                <Button
                    className='free-trial-btn'
                    style={
                        {
                            backgroundImage: "url('/images/reactangle-button.png')",
                        }}>
                    Start free trial
                </Button>
            </Box>

        </Box>
        <Packages isMobile={!isMobile} />

        <FitForLifestyle />

        <FeedbackCarousel />

        <Faqs />

        <Footer />

    </Box >
}

export default Home;