import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';

export default function Faqs() {
    return <>
        <Box maxWidth='74rem' margin={"auto"} marginTop="9rem" p={2} data-aos="fade-up" data-aos-duration="800">
            <Typography textAlign={"left"} marginBottom={"3rem"} className="fitfor-heading" >Frequently Asked Question</Typography>
            <Accordion sx={{ backgroundColor: "#333", color: 'whitesmoke' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>1. What are your gym's operating hours?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Our gym is open from Monday to Saturday, 5:00 AM to 10:00 AM at morning  and 5:00 PM to 10:00 PM at evening.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ backgroundColor: "#333", color: 'whitesmoke' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>2. What types of fitness classes do you offer?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        We offer a diverse range of fitness classes including yoga, high-intensity interval training (HIIT), spinning, strength training, and dance cardio. Check out our class schedule for more details.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ backgroundColor: "#333", color: 'whitesmoke' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>3. How can I sign up for a membership?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Signing up for a membership is easy! Simply visit our Membership page on the website, choose the membership plan that suits you, and follow the online registration steps. You can also visit our front desk during operating hours for in-person sign-ups.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ backgroundColor: "#333", color: 'whitesmoke' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>4. What is your personal training program about?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Our personal training program is designed to provide individualized workouts and guidance tailored to your specific fitness goals. Our certified trainers will work closely with you to create a customized plan, track your progress, and ensure you're on the right track to achieve your objectives.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ backgroundColor: "#333", color: 'whitesmoke' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>5. Is there a childcare service available at your gym?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Yes, we offer a childcare service for our members. Our experienced staff provides a safe and fun environment for your children while you work out. The service is available during morning and evening hours. Please contact us for more information.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    </>;
}