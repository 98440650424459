import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react'
import OTPInput from 'react-otp-input';

const OTP = ({ handleVerifyCode }) => {
    const [otp, setOtp] = useState('');

    return <Box p={5} height={"11rem"} width={"25rem"} sx={{ backgroundColor: 'white', borderRadius: '5px', margin: 'auto', textAlign: 'center', marginTop: '5rem' }}>
        <Typography textAlign={"center"} variant='h5'>
            Enter OTP Sent On Your Mobile
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1.5rem' }}>
            <OTPInput
                inputStyle={{ height: "40px", width: '40px' }}
                value={otp}
                onChange={e => setOtp(e)}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
            />
        </Box>

        <Button
            onClick={() => otp.length === 6 && handleVerifyCode(otp)}
            variant='outlined' sx={{ marginTop: '2rem', width: '60%' }}>Verify</Button>
    </Box>
}

export default OTP