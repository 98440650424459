import { initializeApp } from "firebase/app";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { getFirestore, serverTimestamp, setDoc, doc, updateDoc, arrayUnion, addDoc, getDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDZh6mccKVLOtbes_L-8ou68mWSMHkiJ54",
    authDomain: "ab-fitness-gym.firebaseapp.com",
    projectId: "ab-fitness-gym",
    storageBucket: "ab-fitness-gym.appspot.com",
    messagingSenderId: "557321983409",
    appId: "1:557321983409:web:ce372236c5edd293d39990"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
export { auth, signInWithPhoneNumber, RecaptchaVerifier, db, serverTimestamp, setDoc, doc, updateDoc, arrayUnion, addDoc, getDoc }
