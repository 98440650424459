import React, { useEffect, useState } from 'react'
import Home from './pages/Home';
import "./App.css"
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Gallery from './pages/Gallery';
import Subscribe from './pages/Subscribe';
import { Toaster } from 'react-hot-toast';

const App = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <BrowserRouter >
    <Toaster
      position="top-center"
      reverseOrder={false}
    />
    <Routes>
      <Route path="/" element={<Home isMobile={isMobile} />} />
      <Route path="/about-us" element={<About isMobile={!isMobile} />} />
      <Route path="/gallery" element={<Gallery isMobile={!isMobile} />} />
      <Route path="/ab-subscribe-form" element={<Subscribe isMobile={!isMobile} />} />
      <Route path="/*" element={<Home isMobile={isMobile} />} />
    </Routes>
  </BrowserRouter>
}

export default App; 