import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from "yup"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: 24,
    p: 4,
    transition: "3s"
};

export default function CountactUsForm({ isOpen, onClose }) {

    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            contactNumber: '',
            message: ''
        },
        validationSchema: yup.object({
            fullName: yup
                .string("Enter a valid type")
                .required("Please enter full name"),
            email: yup
                .string("Enter a valid type")
                .required("Please enter email"),
            contactNumber: yup
                .string("Enter a valid type")
                .required("Please enter contact number"),
            message: yup
                .string("Enter a valid type")
                .required("Please enter message")
        }),
        onSubmit: (values) => console.log(values)
    })

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={formik.handleSubmit}>
                    <Box sx={style} >
                        <Typography id="modal-modal-title" variant="h6" component="h2" textAlign={"center"}>
                            Contact Us
                        </Typography>

                        <TextField
                            name="fullName"
                            value={formik.values.fullName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                            id="outlined-basic"
                            label="Enter your full name"
                            variant="outlined"
                            sx={{ width: "100%", marginTop: '1.7rem' }} />

                        <TextField
                            name="contactNumber"
                            value={formik.values.contactNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                            id="outlined-basic"
                            label="Enter your contact number"
                            variant="outlined"
                            sx={{ width: "100%", marginTop: '1rem' }} />

                        <TextField
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                            id="outlined-basic"
                            label="Enter your email"
                            variant="outlined"
                            sx={{ width: "100%", marginTop: '1rem' }} />

                        <TextField
                            name="message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required multiline
                            rows={4}
                            id="outlined-basic"
                            label="Message"
                            variant="outlined"
                            sx={{ width: "100%", marginTop: '1rem' }} />

                        <Button
                            className='contact-submit-btn'
                            type='submit'
                            style={
                                {
                                    backgroundImage: "url('/images/reactangle-button.png')",
                                }}>
                            Submit
                        </Button>
                    </Box>
                </form>
            </Modal>
        </div>
    );
}