import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react'

const TermsConditions = ({ isOpen, onClose }) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '900px',
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll',
    };

    const contentStyle = {
        maxHeight: '480px',
        overflowY: 'auto',
    };
    return <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style} >
            <Typography id="modal-modal-title" variant="h6" component="h2" textAlign={"center"}>
                Terms & Conditions
            </Typography>



            <Box style={contentStyle}>

                <Typography paragraph>
                    <strong>1. Membership</strong>
                </Typography>
                <Typography paragraph>
                    1.1. All individuals must become members to access AB FITNESS ZONE's
                    facilities and services.
                </Typography>
                <Typography paragraph>
                    1.2. Membership is granted upon completion of registration and payment
                    of applicable fees.
                </Typography>
                <Typography paragraph>
                    1.3. Members must provide accurate personal information during
                    registration and keep it up to date.
                </Typography>
                <Typography paragraph>
                    1.4. Membership fees are non-refundable and non-transferable.
                </Typography>

                <Typography paragraph>
                    <strong>2. Access and Hours of Operation</strong>
                </Typography>
                <Typography paragraph>
                    2.1. AB FITNESS ZONE's operating hours may vary. Members will be
                    informed of any changes in advance.
                </Typography>
                <Typography paragraph>
                    2.2. Access may be denied to individuals who violate gym rules and
                    regulations.
                </Typography>

                <Typography paragraph>
                    <strong>3. Use of Facilities</strong>
                </Typography>
                <Typography paragraph>
                    3.1. Members must follow all gym rules, posted signage, and
                    instructions from staff.
                </Typography>
                <Typography paragraph>
                    3.2. Proper attire and footwear must be worn while using gym
                    facilities.
                </Typography>
                <Typography paragraph>
                    3.3. AB FITNESS ZONE is not responsible for lost or stolen personal
                    belongings.
                </Typography>

                <Typography paragraph>
                    <strong>4. Health and Safety</strong>
                </Typography>
                <Typography paragraph>
                    4.1. Members are encouraged to consult with a medical professional
                    before beginning any fitness program.
                </Typography>
                <Typography paragraph>
                    4.2. It is the member's responsibility to inform gym staff of any
                    medical conditions or injuries that may affect their fitness
                    activities.
                </Typography>
                <Typography paragraph>
                    4.3. Members are expected to maintain good personal hygiene while
                    using the gym.
                </Typography>

                <Typography paragraph>
                    <strong>5. Conduct and Behavior</strong>
                </Typography>
                <Typography paragraph>
                    5.1. Respect for fellow members and staff is mandatory.
                </Typography>
                <Typography paragraph>
                    5.2. Inappropriate behavior, harassment, or any form of discrimination
                    will not be tolerated.
                </Typography>
                <Typography paragraph>
                    5.3. Members must refrain from excessive noise and disruptive
                    behavior.
                </Typography>

                <Typography paragraph>
                    <strong>6. Payment and Fees</strong>
                </Typography>
                <Typography paragraph>
                    6.1. Membership fees are due as per the agreed schedule.
                </Typography>
                <Typography paragraph>
                    6.2. Failure to make timely payments may result in membership
                    suspension or termination.
                </Typography>

                <Typography paragraph>
                    <strong>7. Cancellation and Refunds</strong>
                </Typography>
                <Typography paragraph>
                    7.1. Members may cancel their membership by providing written notice
                    in accordance with the cancellation policy.
                </Typography>
                <Typography paragraph>
                    7.2. Refunds are subject to the terms of the membership agreement.
                </Typography>

                <Typography paragraph>
                    <strong>8. Liability and Waiver</strong>
                </Typography>
                <Typography paragraph>
                    8.1. AB FITNESS ZONE is not liable for any injuries, accidents, or
                    illnesses that may occur on its premises.
                </Typography>
                <Typography paragraph>
                    8.2. Members are encouraged to carry their own insurance coverage for
                    any potential risks.
                </Typography>

                <Typography paragraph>
                    <strong>9. Amendments</strong>
                </Typography>
                <Typography paragraph>
                    9.1. AB FITNESS ZONE reserves the right to modify these terms and
                    conditions. Members will be notified of any changes in advance.
                </Typography>

                <Typography paragraph>
                    <strong>10. Termination</strong>
                </Typography>
                <Typography paragraph>
                    10.1. AB FITNESS ZONE reserves the right to terminate memberships for
                    violation of these terms and conditions without refund.
                </Typography>

                <Typography paragraph>
                    <strong>11. Contact Information</strong>
                </Typography>
                <Typography paragraph>
                    11.1. For inquiries or concerns, please contact AB FITNESS ZONE at
                    [Contact Information].
                </Typography>

                <Typography paragraph>
                    - {" " + " " + " "}By becoming a member of AB FITNESS ZONE, you agree to abide by these
                    terms and conditions. We are committed to providing a safe and
                    enjoyable fitness experience for all our members.
                </Typography>

            </Box>


            <Box textAlign={"center"}>
                <Button
                    onClick={onClose}
                    className='contact-submit-btn'
                    variant='contained'
                    type='submit'>
                    I Agree
                </Button>
            </Box>
        </Box>
    </Modal>
}

export default TermsConditions