import React from 'react'
import Navbar from '../components/Navbar'
import { Box, ImageList, ImageListItem } from '@mui/material';
import Footer from '../components/Footer';

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const Gallery = ({ isMobile }) => {
    return <Box style={{
        backgroundImage: "url('/images/landing.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100vw',
        height: "100vh",
    }}>
        <Box
            sx={isMobile ? { padding: '0 4rem 0 4rem' } : {}}
        >
            <Navbar isMobile={isMobile} />

            <Box display={"flex"} justifyContent={"center"} mt={5}>
                <ImageList
                    sx={{ width: 1000, height: 500 }}
                    variant="quilted"
                    cols={4}
                    rowHeight={121}
                >
                    {itemData.map((item) => (
                        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                            <img
                                {...srcset(item.img, 121, item.rows, item.cols)}
                                alt={item.title}
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>

        </Box>
        <Footer />
    </Box>
}

export default Gallery;




const itemData = [
    {
        img: '/images/gallery/0.jpeg',
        title: 'Breakfast',
        rows: 2,
        cols: 2,
    },
    {
        img: '/images/gallery/1.jpeg',
        title: 'Burger',
    },
    {
        img: '/images/gallery/2.jpeg',
        title: 'Camera',
    },
    {
        img: '/images/gallery/3.jpeg',
        title: 'Coffee',
        cols: 2,
    },
    {
        img: '/images/gallery/4.jpeg',
        title: 'Hats',
        cols: 2,
    },
    {
        img: '/images/gallery/5.jpeg',
        title: 'Honey',
        author: '@arwinneil',
        rows: 2,
        cols: 2,
    },
    {
        img: '/images/gallery/6.jpeg',
        title: 'Basketball',
    },
    {
        img: '/images/gallery/7.jpeg',
        title: 'Fern',
    },
    {
        img: '/images/gallery/8.jpeg',
        title: 'Mushrooms',
        rows: 2,
        cols: 2,
    },
    {
        img: '/images/gallery/9.jpeg',
        title: 'Tomato basil',
    },
    {
        img: '/images/gallery/3.jpeg',
        title: 'Sea star',
    },
    {
        img: '/images/gallery/5.jpeg',
        title: 'Bike',
        cols: 2,
    },
];