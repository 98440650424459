import { Box, Typography } from '@mui/material'
import React from 'react'
import "./../styles/footer.css"

const Footer = () => {
    return <>
        <Box style={{
            backgroundImage: "url('/images/footer-background.png')",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '100vw',
            height: "26rem",
            marginTop: '4rem'
        }}>

            <Typography className="footer-heading">AB FITNESS ZONE</Typography>

            <Box className="footer-desc" textAlign={"center"}>
                Join us today and experience the transformative power of our fitness classes. Don't wait to start your fitness journey. Take the first step towards a healthier, stronger you. Let's sweat, have fun, and make fitness a way of life together!
                <br />
                <br />
                Address : Plot No. 03, In Front Of Mayuri Hotel, Jadhavwadi, ch. sambhajinagar
                <br />
                Contact no : +91 9021555657
            </Box>

            <Box className='logos-container' textAlign={"center"} mt={5}>

                <a href="https://twitter.com/" target='_blank'>
                    <img className='connect-logo' width={48} height={48} src="/icons/twitter.png" alt="git-logo" />
                </a>
                <a href="https://www.facebook.com/" target='_blank'>
                    <img className='connect-logo' width={48} height={48} src="/icons/facebook.png" alt="git-logo" />
                </a>
                <a href="https://instagram.com/balkrishna_wadekar?igshid=ZGUzMzM3NWJiOQ==" target='_blank'>
                    <img className='connect-logo' width={48} height={48} src="/icons/instagram.png" alt="git-logo" />
                </a>
            </Box>

            <hr style={{ maxWidth: '90%' }}></hr>
            <Box className="footer-desc" style={{ marginTop: 0, bottom: 0 }}>
                @ All rights reserved | AB Fitness Zone
            </Box>
        </Box >
    </>
}

export default Footer