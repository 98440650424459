import styled from '@emotion/styled';
import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const About = ({ isMobile }) => {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: 'transparent',
        padding: "1rem",
        textAlign: 'center',
        color: "#F5F5F5",
        height: '90vh',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 1000)'
    }));

    return <Box sx={{
        backgroundImage: "url('https://images.unsplash.com/photo-1593079831268-3381b0db4a77?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2938&q=80')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100vw',
        height: { md: "140vh", xs: '240vh' },
    }}
    >
        <Box sx={isMobile ? { padding: '0 4rem 0 4rem' } : {}}>
            <Navbar isMobile={isMobile} />
            <Grid container spacing={2} columns={16} mt={2}>
                <Grid item xs={16} md={8}>
                    <Item >
                        <img style={{ borderRadius: "5px", marginTop: '4rem' }} src="/images/gym-owner.jpeg" alt="gym-owner" height={"520vh"} width={"380vw"} />
                    </Item>
                </Grid>

                <Grid item xs={16} md={8}>
                    <Item>
                        <Box height={"100%"} width={"100%"}>

                            <Typography mt={isMobile ? 12 : 0} className="fitfor-heading" >Fit for your lifestyle</Typography>

                            <Typography textAlign={"left"} mt={3} fontStyle={"italic"}>
                                Wake up with a sunrise meditation, sweat it out with lunchtime HIIT, or unwind with an evening flow. You’ll find movement for every mood with classes sorted by time, style, and skill level.                            Wake up with a sunrise meditation, sweat it out with lunchtime HIIT, or unwind with an evening flow. You’ll find movement for every mood with classes sorted by time, style, and skill level.
                            </Typography>

                            <Typography textAlign={"left"} mt={3} fontStyle={"italic"}>
                                Wake up with a sunrise meditation, sweat it out with lunchtime HIIT, or unwind with an evening flow. You’ll find movement for every mood with classes sorted by time, style, and skill level.                            Wake up with a sunrise meditation, sweat it out with lunchtime HIIT, or unwind with an evening flow. You’ll find movement for every mood with classes sorted by time, style, and skill level.
                            </Typography>

                            <Typography textAlign={"right"} mt={3} p={5} fontStyle={"italic"}>
                                GYM Director : Viki Umbarkar
                            </Typography>

                        </Box>
                    </Item>
                </Grid>
            </Grid>
        </Box>
        <Footer />
    </Box>
}

export default About;