import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import './../styles/subscribe.css';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { useFormik } from 'formik';
import * as yup from 'yup'
import Footer from '../components/Footer';
import OTP from '../components/OTP';
import { RecaptchaVerifier, auth, signInWithPhoneNumber, db, serverTimestamp, setDoc, doc, updateDoc, arrayUnion, addDoc, getDoc } from '../firebase.config';
import { toast, } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import TermsConditions from '../components/TermsConditions';

const Subscribe = ({ isMobile }) => {

    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [isClickedSubmit, setIsClickedSubmit] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const { state: { selectedPackage } } = useLocation()

    const onClose = () => setIsOpen(false)

    const formik = useFormik({
        initialValues: {
            name: '',
            fatherName: '',
            birthDate: '',
            address: '',
            profession: '',
            contactNumber: '',
            PanUidDetail: '',
            gender: '',
            ageNumber: ''
        },

        validationSchema: yup.object({
            name: yup
                .string("Please enter a valid type")
                .required("Please enter your full name"),
            fatherName: yup
                .string("Please enter a valid type")
                .required("Please enter your father name"),
            birthDate: yup
                .string("Please enter a valid type")
                .required("Please enter your birth date"),
            address: yup
                .string("Please enter a valid type")
                .required("Please enter your birth date")
                .max(50, "Character limit is upto 50 only"),
            profession: yup
                .string("Please enter a valid type")
                .required("Please enter profession"),
            contactNumber: yup
                .string("Please enter a valid type")
                .required("Please enter your phone number")
                .min(10, "character limit is 10")
                .max(10, "character limit is 10"),
            PanUidDetail: yup
                .string("Please enter a valid type")
                .required("Please enter your PAN / Aadhar detail"),
            gender: yup
                .string("Please enter a valid type")
                .required("Please select your gender"),
            ageNumber: yup
                .string("Please enter a valid type")
                .required("Please enter select your age"),
        }),
        onSubmit: (values) => {
            console.log(values)
            handleSendCode(`+91${values.contactNumber}`)
        }
    })

    const [confirmationResult, setConfirmationResult] = useState('')

    const handleSendCode = async (phoneNumber) => {

        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // onSignInSubmit();
            }
        });

        const appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                setConfirmationResult(confirmationResult)
                window.confirmationResult = confirmationResult;
                toast.success(`OTP is sent on your mobile number ${phoneNumber}`)
                setIsClickedSubmit(true)
            }).catch((error) => {
                // Error; SMS not sent
                // ...
                toast.error("Something went wrong! please try again later")
                console.log(error)
            });
    };

    const handleVerifyCode = async (code) => {
        confirmationResult.confirm(code).then((result) => {
            // User signed in successfully.
            toast.success("Verification successful!")
            console.log("Verification successful!")
            const user = result.user;
            console.log(user)
            addUserToDb(user.uid)
            handleSetSubscribePackage(user.uid, selectedPackage)
            // ...
        }).catch((error) => {
            toast.error("Please enter valid credentials!")
            // User couldn't sign in (bad verification code?)
            // ...
        });
    };

    const addUserToDb = async (userUID) => {

        const data = formik.values

        try {
            console.log("DB entry program run!")
            await setDoc(doc(db, "users", userUID), data);

        } catch (error) {
            console.log("Error : ", error)
        }
    }

    const handleSetSubscribePackage = async (userUID, selectedPackage) => {
        try {
            const userRef = doc(db, "subscriptions", userUID);

            const userDoc = await getDoc(userRef);
            const userData = userDoc.data() || {};

            if (!userData.subscribedPackages) {
                userData.subscribedPackages = [];
            }

            userData.subscribedPackages.push(selectedPackage);

            const result = await userDoc.exists()
                ? updateDoc(userRef, {
                    subscribedPackages: userData.subscribedPackages,
                })
                : setDoc(userRef, {
                    subscribedPackages: userData.subscribedPackages,
                })

            console.log(result)
            toast.success("Registration successful! Your application is successfully submitted to AB FITNESS ZONE. Our respective admin will contact you soon! Thanks for your interest in joining us!");
        } catch (error) {
            console.error("Error: ", error);
            toast.error("Something went wrong! Please try again later.");
        }
    };


    useEffect(() => {
        return () => {
            window.scrollTo(0, 0)
        }
    }, [])

    return (
        <Box
            sx={{
                backgroundImage: "url('https://images.unsplash.com/photo-1517836357463-d25dfeac3438?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80')",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100vw',
                height: { md: "140vh", xs: '240vh' },
            }}
        >
            <Box sx={isMobile ? { padding: '0 4rem 0 4rem' } : {}}>
                <Navbar isMobile={isMobile} />

                {
                    !isClickedSubmit
                        ? <Box className="subscribe-form-parent" mt={6}>
                            <div id="sign-in-button"></div>
                            <form onSubmit={formik.handleSubmit}>

                                <Typography variant="h6" component="h2" textAlign="center">
                                    JOIN AB FITNESS CLUB
                                </Typography>

                                <TextField
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                    id="outlined-basic-name"
                                    label="Enter Your Full Name"
                                    variant="outlined"
                                    sx={{ width: "100%", marginTop: '1.7rem', color: 'white' }}
                                />

                                <TextField
                                    name="fatherName"
                                    value={formik.values.fatherName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                    id="outlined-basic-father"
                                    label="Father Name"
                                    variant="outlined"
                                    sx={{ width: "100%", marginTop: '1rem' }}
                                />

                                <TextField
                                    name="birthDate"
                                    value={formik.values.birthDate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                    id="outlined-basic-father"
                                    label="Birth Date"
                                    variant="outlined"
                                    sx={{ width: "100%", marginTop: '1rem' }}
                                />

                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Select a date"
                                value={selectedDate}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider> */}

                                <TextField
                                    required
                                    name="address"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    multiline
                                    rows={4}
                                    id="outlined-basic-message"
                                    label="Address"
                                    variant="outlined"
                                    sx={{ width: "100%", marginTop: '1rem' }}
                                />

                                <TextField
                                    required
                                    name="profession"
                                    value={formik.values.profession}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    id="outlined-basic-father"
                                    label="Profession"
                                    variant="outlined"
                                    sx={{ width: "100%", marginTop: '1rem' }}
                                />

                                <TextField
                                    name="contactNumber"
                                    value={formik.values.contactNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                    id="outlined-basic-father"
                                    label="Contact Number"
                                    variant="outlined"
                                    sx={{ width: "100%", marginTop: '1rem' }}
                                />

                                <TextField
                                    required
                                    name="PanUidDetail"
                                    value={formik.values.PanUidDetail}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    id="outlined-basic-father"
                                    label="Aadhar No. / Pan No."
                                    variant="outlined"
                                    sx={{ width: "100%", marginTop: '1rem' }}
                                />

                                <FormControl required sx={{ width: "100%", marginTop: '1rem' }}>
                                    <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
                                    <Select
                                        name="gender"
                                        value={formik.values.gender}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="Age"
                                    >

                                        <MenuItem value={"Male"}>Male</MenuItem>
                                        <MenuItem value={"Female"}>Female</MenuItem>
                                        <MenuItem value={"Other"}>Other</MenuItem>

                                    </Select>
                                </FormControl>



                                <FormControl required sx={{ width: "100%", marginTop: '1rem' }}>
                                    <InputLabel id="demo-simple-select-helper-label">Age</InputLabel>
                                    <Select
                                        name="ageNumber"
                                        value={formik.values.ageNumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="Age"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>

                                        {
                                            [...Array(100).keys()].map(x => x + 1).map(item => <MenuItem value={item}>{item}</MenuItem>)
                                        }

                                    </Select>
                                </FormControl>

                                <FormControlLabel required control={<Checkbox />} label="I agree all terms & conditions" />
                                <span onClick={() => setIsOpen(true)} style={{ color: '#005b94', cursor: 'pointer' }}>
                                    Terms & Conditions
                                </span>


                                <Box textAlign="center">
                                    <Button
                                        type='submit'
                                        className='contact-submit-btn'
                                        style={{ backgroundImage: "url('/images/reactangle-button.png')" }}
                                    >
                                        Subscribe
                                    </Button>
                                </Box>

                            </form>
                        </Box>
                        : <OTP handleVerifyCode={handleVerifyCode} />
                }
            </Box>

            <TermsConditions isOpen={isOpen} onClose={onClose} />

            <Footer />
        </Box>
    );
}

export default Subscribe;