import styled from '@emotion/styled';
import { Box, Button, Paper, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const Packages = () => {
    const Item = styled(Paper)(() => ({
        padding: "1rem",
        textAlign: 'center',
        backgroundColor: "gray",
        color: "white",
        width: '15rem',
        height: '20rem',
        margin: '3rem 1rem 2rem 1rem',
        paddingTop: '0',
        overflow: 'hidden'
    }));

    const navigate = useNavigate()

    const data = [
        {
            packageType: 'gym',
            packageName: 'AB Standard',
            duration: '1 Month',
            price: '1050/- Rs',
            discount: '5%',
            finalAmount: '1000/- Rs Only'
        },
        {
            packageType: 'gym',
            packageName: 'AB Silver',
            duration: '3 Month',
            price: '3000/- Rs',
            discount: '15%',
            finalAmount: '2550/- Rs Only'
        },
        {
            packageType: 'gym',
            packageName: 'AB Gold',
            duration: '6 Month',
            price: '6000/- Rs',
            discount: '20%',
            finalAmount: '4800/- Rs Only'
        },
        {
            packageType: 'gym',
            packageName: 'AB Diamond',
            duration: '1 Year',
            price: '12000/- Rs',
            discount: '25%',
            finalAmount: '9000/- Rs Only'
        },
        {
            packageType: 'gymcardio',
            packageName: 'AB Standard',
            duration: '1 Month',
            price: '1260/- Rs',
            discount: '5%',
            finalAmount: '1200/- Rs Only'
        },
        {
            packageType: 'gymcardio',
            packageName: 'AB Silver',
            duration: '3 Month',
            price: '3600/- Rs',
            discount: '15%',
            finalAmount: '3060/- Rs Only'
        },
        {
            packageType: 'gymcardio',
            packageName: 'AB Gold',
            duration: '6 Month',
            price: '7200/- Rs',
            discount: '20%',
            finalAmount: '5760/- Rs Only'
        },
        {
            packageType: 'gymcardio',
            packageName: 'AB Diamond',
            duration: '1 Year',
            price: '14400/- Rs',
            discount: '25%',
            finalAmount: '10800/- Rs Only'
        },
    ]

    return (
        <Box mt={3}>
            <img src="/images/gradient.png" alt="gradient" className='fitfor-lifestyle-gradient' />
            <Typography className="fitfor-heading" data-aos="fade-up" data-aos-duration="800" >Join Us Today</Typography>

            <Box mt={5} >
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '116rem', justifyContent: 'center' }}>
                        {
                            data.map((item, index) => <Item key={index} className='package-box' data-aos="fade-up" data-aos-duration="800" >
                                <Box p={1.5} className="gym-package-title">
                                    {item.packageType === "gym" ? "GYM" : "GYM + CARDIO"}
                                </Box>
                                <br />
                                <Typography fontFamily={"Outfit"} fontWeight={600} fontSize={18}>{item.packageName}</Typography>
                                <br />
                                <hr />
                                <Typography textAlign={"left"} ml={1}>Duration : {item.duration}</Typography>
                                <hr />
                                <Typography textAlign={"left"} ml={1}>Price : {item.price}</Typography>
                                <hr />
                                <Typography textAlign={"left"} ml={1}>Discount : {item.discount}</Typography>
                                <hr />
                                <Typography textAlign={"left"} ml={1}>Final Price : {item.finalAmount}</Typography>
                                <hr />
                                <Button
                                    className='subscribe-package-btn'
                                    onClick={() => navigate("/ab-subscribe-form", { state: { selectedPackage: { ...item, isAdminApproved: false } } })}
                                    style={
                                        {
                                            backgroundImage: "url('/images/reactangle-button.png')",
                                            marginTop: '0 !important',
                                            letterSpacing: '1.8 !important'
                                        }}>
                                    Subscribe
                                </Button>

                            </Item>)
                        }
                    </div>
                </div>
            </Box>
        </Box>
    );
}

export default Packages;